const cookiePolicy = document.getElementById('cookiePolicy')
const doesCookieExist = getCookie('acknowledged_cookie_policy')

if (!doesCookieExist) {
	cookiePolicy.classList.add('active')

	cookiePolicy.querySelector('.accept-btn').addEventListener('click', e => {
		cookiePolicy.classList.remove('active')
		cookiePolicy.classList.add('hidden')

		setCookie('acknowledged_cookie_policy', 1, 362)
	})
}

function getCookie(name) {
	var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
	return v ? v[2] : null
}

function setCookie(name, value, days) {
	var d = new Date()
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
	document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
}
